import React from "react"
import {withPrefix} from "gatsby";
import ComingSoon from "../components/comingSoon";

export default function Home() {
    return (<ComingSoon pageTitle="Training Centre Manager">
        <div className="h-100">

            <div className="full-height-layout d-flex align-items-center">
                <div className="coming-soon-content font-gordita">

                    <img src={withPrefix('images/wetrainhub-logo-colour.svg')} alt="wetrainHUB" className="m-auto"/>

                    <h6>COMING SOON</h6>
                    <h1>One platform to manage your training centre!</h1>
                    <div className="row">
                        <div className="col-lg-9 m-auto">
                            <p>Follow us on social media for the latest updates!</p>
                        </div>
                    </div>

                    <ul className="social-icon d-flex justify-content-center">
                        <li>
                            <a href="https://www.facebook.com/wetrainhub" target="_blank" rel="noreferrer">
                                <i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li>
                            <a href="https://www.linkedin.com/company/wetrainhub" target="_blank" rel="noreferrer">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>

                    <img src={withPrefix('images/shape/179.svg')} alt="" className="shapes shape-one"/>
                    <img src={withPrefix('images/shape/180.svg')} alt="" className="shapes shape-two"/>
                    <img src={withPrefix('images/shape/181.svg')} alt="" className="shapes shape-three"/>
                    <img src={withPrefix('images/shape/182.svg')} alt="" className="shapes shape-four"/>
                    <img src={withPrefix('images/shape/183.svg')} alt="" className="shapes shape-five"/>
                    <img src={withPrefix('images/shape/184.svg')} alt="" className="shapes shape-six"/>
                    <img src={withPrefix('images/shape/185.svg')} alt="" className="shapes shape-seven"/>
                    <img src={withPrefix('images/shape/186.svg')} alt="" className="shapes shape-eight"/>
                    <img src={withPrefix('images/shape/187.svg')} alt="" className="shapes shape-nine"/>
                    <img src={withPrefix('images/shape/188.svg')} alt="" className="shapes shape-ten"/>
                </div>

            </div>
        </div>
    </ComingSoon>);
}