import React from "react"
import {Helmet} from "react-helmet";
import {withPrefix} from "gatsby"

export default function ComingSoon({children, pageTitle}) {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{pageTitle} | wetrainHUB</title>
            </Helmet>

              {children}

            <Helmet>
                <script src={withPrefix('vendor/jquery.min.js')} type="text/javascript"/>
                <script src={withPrefix('vendor/popper.js/popper.min.js')} type="text/javascript"/>
                <script src={withPrefix('vendor/bootstrap/js/bootstrap.min.js')} type="text/javascript"/>
                <script src={withPrefix('vendor/mega-menu/assets/js/custom.js')} type="text/javascript"/>

                <script src={withPrefix('vendor/jquery.appear.js')} type="text/javascript"/>
                <script src={withPrefix('vendor/jquery.countTo.js')} type="text/javascript"/>
                <script src={withPrefix('vendor/slick/slick.min.js')} type="text/javascript"/>
                
            </Helmet>
        </div>
    )
}